.logo {
  display: block;
  position: relative;

  &::after {
    content: '';
    width: 102px;
    height: 21px;
    position: absolute;
    left: 0;
    top: 0;
    background: #fff url(../../img/logo--hover.png) center no-repeat;
    display: none;
  }

  &:hover::after {
    display: block;
  }

  img {
    display: block;
  }

  & > h1 {
    display: none;
  }
}

.page-header {
  .clearfix;
  border-bottom: 1px solid #f4f4f4;
  box-shadow: 2px 3px 24px rgba(0, 0, 0, 0.21);

  width: ~'calc(100% -  60px)';
  min-width: 260px;
  padding: 50px 40px;
  background: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  left: -100%;
  .standart-transition(left, 0.3s);


  &--open {
    left: 0;
  }

  & > .container {
    min-width: 0;
  }

  @media @desktop {
    width: auto;
    padding: 0;
    position: static;
    box-shadow: none;
  }


  &__topbar {
    display: none;
    height: 36px;
    box-shadow: inset 0 -15px 10px -10px #f5f5f5;

    @media @desktop {
      display: block;
    }
  }

  &__middle {
    display: flex;
    flex-wrap: wrap;

    @media @desktop {
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      padding: 30px 0 10px 0;
    }

  }

  &__search {
    order: 2;
    width: 100%;
    margin-bottom: 30px;

    @media @desktop {
      order: 0;
      width: 33.33%;
      margin-bottom: 0;
    }
  }
  &__logo {
    margin-bottom: 20px;

    @media @desktop {
      margin-bottom: 0;
    }
  }
  &__social {
    order: 3;
    width: 100%;
    margin-top: 30px;

    @media @desktop {
      order: 0;
      text-align: right;
      width: 33.33%;
      margin-top: 0;
    }
  }

}

.sm-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: @col-padding;

  @media @desktop {
    display: none;
  }

  &__menu-btn {
    .clear-btn-style;

    &--close .ic--menu{

    }
  }
}

.topbar {
  @topbar-height: 36px;
  .font-title;
  font-size: 12px;

  &__left {
    float: left;
  }

  &__right {
    float: right;
  }

  &__mail {
    display: block;
    height: @topbar-height;
    line-height: @topbar-height;
    color: #6e6e6e;
  }

  &__item {
    float: left;
    height: @topbar-height;
    padding: 0 5px;
    border-left: 1px solid @c-border;
    line-height: @topbar-height;
    font-size: 10px;

    .ic {
      vertical-align: middle;
    }
  }

}
