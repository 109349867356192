.content-bl {
  padding: 20px 30px;
  border-radius: 7px;
  background-color: #f8f8f8;
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 15px;
  line-height: 1.5;

  &__header {
    margin-bottom: 30px;
  }

}
