@tag-review:    #91bec4;
@tag-news:      #e49bac;

.tag {
  display: inline-block;
  padding: 3px 4px;
  border-radius: 4px;
  background: @c-active;

  color: @theme-white;
  font-family: GothamPro;
  font-size: 8px;
  font-weight: 700;
  line-height: 9px;
  text-decoration: none;
  text-transform: uppercase;

  &--review { background: @tag-review;}
  &--news { background: @tag-news;}

  @media @desktop {
    padding: 3px 5px;
    font-size: 9px;
    line-height: 10px;
  }
}
