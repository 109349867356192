.info-text {
  margin-bottom: 30px;

  &__inner {
    padding: 23px 17px;
    background: @c-bg;
    font-size: 13px;
    color: #595959;
  }
}
