.menu {
  list-style-type: none;
  .font-title;

  &__link {
    display: block;
    padding: 10px 0;
    color: #424242;
    font-size: 12px;
    text-decoration: none;

    &--disabled {
      color: @c-mobile-title;
    }

    &--active,
    &:active {
      color: @c-active;
    }
  }

  @media @desktop {
    display: flex;
    justify-content: space-between;


    &__link {
      padding: 30px 0;
      font-size: 15px;
      position: relative;

      &::before {
        content: '';
        display: block;
        width: 0;
        height: 2px;
        background: @c-active;
        position: absolute;
        left: 50%;
        bottom: 0;
        .standart-transition;
      }

      &--active {
        color: #424242;
        cursor: default;
      }

      &--active::before,
      &:hover::before {
        left: 0;
        width: 100%;
      }

    }
  }

}
