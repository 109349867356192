.stat-bl {
  font-family: GothamPro;
  font-size: 10px;
  font-weight: 500;
  color: @c-mobile-title;

  &__item {
    display: inline-block;
    margin-right: 15px;

    span {
      display: inline-block;
      line-height: 14px;
      vertical-align: middle;
    }
  }

  .fa {
    font-size: 13px;
  }

  &--post {
    .fa {
      color: #cdd7d8;
      margin-right: 5px;
    }
  }
}
