.include-custom-font(@fontname: arial, @fontfile: arial, @weight: normal, @style: normal){
  font-family: @fontname;
  src:url('../fonts/@{fontfile}.eot');
  src:url('../fonts/@{fontfile}.eot?#iefix') format('embedded-opentype'),
      url('../fonts/@{fontfile}.woff') format('woff'),
      url('../fonts/@{fontfile}.ttf') format('truetype'),
      url('../fonts/@{fontfile}.svg#icon') format('svg');
  font-weight: @weight;
  font-style: @style;
}

.clearfix {
  &::before, &::after {
    content: "";
    display: table;
  }

  &::after {
    clear:both;
  }
}

.clear {
  clear: both;
}

.standart-transition(@params: all, @time: 0.3s) {
  transition: @params @time;
}

