* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  min-width: @container-width-min;
  min-height: 100%;
  height: 100%;
  color: @theme-black;
  font: @font-size/1.4 'Roboto', Arial, sans-serif;
  background: @theme-white;
}

a {
  transition: color 0.3s, background 0.3s;

  &:hover {
    text-decoration: none;
  }
}

p {
  margin: 15px 0;

  &:first-child {
    margin-top: 0;
  }
}

img {
  max-width: 100%;
  height: auto !important;
}

button {
  outline-width: 0;
  border: none;
  background: transparent;
}

.link {
  &--main {
    color: @c-active;
  }
}


.container {
  .clearfix;

  max-width: @container-width;
  min-width: @container-width-min;
  padding: 0 @col-padding;
  margin: 0 auto;
}

.align-center { text-align: center;}
.align-left { text-align: left;}
.align-right { text-align: right;}
.centered-bl { margin: 0 auto;}

.pull-right { float: right;}
.pull-left  { float: left;}

.img-wr {
  overflow: hidden;
}






