.recommended {

  &__item {
    .clearfix;
    display: block;
    padding: 24px 17px;
    height: 117px;
    border-bottom: 2px solid #eeeeee;
    text-decoration: none;


    &:active,
    &:hover {
      .recommended__title {
        text-decoration: underline;
      }
      .recommended__btn {
        background: @c-active;
        color: @theme-white;
      }
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {

      .recommended__img::before {
        content: '';
        display: block;
        width: 46px;
        height: 56px;
        position: absolute;
        left: -23px;
        top: -23px;
        background-position: center;
        background-repeat: no-repeat;
      }

    }

    &:nth-child(1) .recommended__img::before { background-image: url(../../img/medal-1.png);}
    &:nth-child(2) .recommended__img::before { background-image: url(../../img/medal-2.png);}
    &:nth-child(3) .recommended__img::before { background-image: url(../../img/medal-3.png);}
  }

  &__btn {
    width: 109px;
    height: 28px;
    padding: 0;
    text-align: center;
  }

  &__img {
    float: left;
    width: 65px;
    height: 65px;
    margin-right: 12px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__title {
    color: #424242;
    font-family: GothamPro;
    font-size: 14px;
    font-weight: 700;
    margin: 6px 0;
  }
}
