.post {
  .clearfix;
  padding: 23px 17px 10px 17px;
  font-size: 11px;
  border-radius: 7px;
  background: @c-bg url(../../img/ic-chip-5.svg) right -27px top -29px no-repeat;
  background-size: 100px;

  & + & {
    margin-top: 15px;
  }

  &:nth-of-type(5n+1) { background-image: url(../../img/ic-chip-1.svg); }
  &:nth-of-type(5n+2) { background-image: url(../../img/ic-chip-2.svg); }
  &:nth-of-type(5n+3) { background-image: url(../../img/ic-chip-3.svg); }
  &:nth-of-type(5n+4) { background-image: url(../../img/ic-chip-4.svg); }

  &__img {
    float: left;
    width: 100px;
    height: 100px;
    line-height: 100px;
    margin: 0 12px 12px 0;

    & > img {
      max-width: 100%;
      max-height: 100%;
      vertical-align: middle;
    }
  }

  &__title {
    display: block;
    margin: 10px 0;
    color: #414141;
    font-family: GothamPro;
    font-size: 15px;
    font-weight: 700;
    line-height: 1.1;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  @media @desktop {
    padding: 36px 28px 16px 28px;
    font-size: 13px;
    background-position: right -35px top -35px;
    background-size: 120px;

    & + & {
      margin-top: 27px;
    }

    &__img {
      width: 180px;
      height: 180px;
      line-height: 180px;
      margin: 0 24px 24px 0;
    }

    &__tag {
      margin-top: 10px;
    }

    &__title {
      font-size: 22px;
    }
  }
}

.post-list {

  &__btn-more {
    display: block;
    margin: 25px 0;
  }

  @media @desktop {
    &__btn-more {
      width: 190px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
