@card-margin: 2px;
@card-width: 212px;
@card-height: 190px;

.card-list {
  margin-bottom: 15px;
  overflow: hidden;

  @media (min-width: 480px) {
    margin-left: -1 * @card-margin;
  }

}



.card {
  display: block;
  width: 100%;
  height: 190px;
  position: relative;
  overflow: hidden;
  overflow-wrap: break-word;

  color: @theme-white;
  margin-bottom: @card-margin;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.75));
    z-index: 1;
  }

  &__bg {
    height: @card-height;
    position: relative;
    overflow: hidden;

    img {
      display: block;
      min-width: 100%;
      min-height: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }
  }

  &__inner {
    width: 100%;
    max-height: 100%;
    padding: 15px 23px;
    position: absolute;
    bottom: 0;
    z-index: 1;
  }

  &__title {
    display: block;
    font-family: GothamPro;
    font-size: 16px;
    font-weight: 700;
    max-height: 2.6em;
    overflow: hidden;
  }

  &__txt {
    display: block;
    font-size: 12px;
    max-height: 5.6em;
    overflow: hidden;
  }

  &__stat {
    color: @theme-white;
    font-size: 12px;
    font-weight: 400;
  }


  @media (min-width: 480px) {
    float: left;
    width: ~'calc(50% - @{card-margin})';
    margin-left: @card-margin;


    &:first-child {
      height: @card-height*2 + @card-margin;

      .card__bg {
        height: @card-height*2 + @card-margin;
      }
    }
  }

  @l-width: (212px + @card-margin) * 2 + @card-margin;

  @media (min-width: 700px) {
    float: left;
    width: @card-width;
    margin-left: @card-margin;

    &:first-child {
      width: ~'calc(100% - @{l-width})';
    }
  }
}
