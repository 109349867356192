.pagination {
  list-style-type: none;
  text-align: center;
  cursor: default;
  margin: 40px 0;

  &__item {
    display: inline-block;
    margin: 10px 2px;

    &:first-child,
    &:last-child {
      margin-right: 10px;
      margin-left: 10px;
    }

    &--disabled {
      .pagination__link {
        color: #d1d1d1;
        pointer-events: none;
      }
    }

    &--active {
      .pagination__link {
        border-color: @c-tags;
        pointer-events: none;
      }
    }
  }

  &__link {
    display: block;
    width: 34px;
    height: 34px;
    color: @theme-black;
    font: 500 15px/32px GothamPro;
    border: 2px solid transparent;
    border-radius: 50%;
    text-decoration: none;

    &:hover {
      color: @c-tags;
    }
  }
}
