@crmbs-color: #aaa;

.breadcrumbs {
  list-style-type: none;
  font-size: 12px;
  color: @crmbs-color;
  cursor: default;
  margin-bottom: 25px;
  margin-top: 10px;

  li {
    display: inline-block;

    & + li {
      padding-left: 11px;
      background: url(../../img/ic-breadcrumbs-arr.svg) left center no-repeat;
    }
  }

  a {
    color: @crmbs-color;
  }

  span {
  }
}
