.ic {
  display: inline-block;
  width: 28px;
  height: 28px;
  vertical-align: text-bottom;
  background: center no-repeat;
}
.ic--menu { background-image: url(../../img/ic-menu.svg);}
.ic--rkn { background-image: url(../../img/ic-rkn.png);}
.ic--rkn-light { background-image: url(../../img/ic-rkn--light.png);}
.ic--smi { background-image: url(../../img/ic-smi.png);}
.ic--smi-light { background-image: url(../../img/ic-smi--light.png);}
.ic--rga { width: 40px; background-image: url(data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAJCAQAAABgKD80AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQfhDBQPOQQJ8kHQAAABkElEQVQozy3NPUjUcQAG4OfOxG+USMqiKLoGzb5OSiLQCILKwcCEGqzGCBoagwaDPoaaglBJkIogHRqMwsgMkoqusqCoXIJKaNITL//i/S6u4e6d3nd4eGP5EdVf/n2wVrJkIJbJr0apk2rAnH6LIsF5mxUy5YmLEPc3ln85ZEq7rC4TjrjmgF7TvirzU6tOZ63QbkG2yDuN+qwZxFPRrOOuuKfMYatst8eAH6ZxStKAHbb66H4Rz9goob8wFuMPNLvtk2oEQTmeYZOcd3qKaL3uYhvUYVifXOE9YV5KwgJi6vU5YdJ7Nf6IVIDvHpv0FhnjWiTVuVHgaRWapFUib8lRtca0YKUyyyByQZs0pvwy6Kpqzwt8nznn5M2jVKRVv4xOVGkyDJJOW+MQxt3Updug12YQ319Z64VbjgneSLtjyVOjLskY8sgZ3/w2IcKI6/ZK2KJN5KDZqpLejkZVy+XZxmw8dzeXCPHQEFrD7jAWtoWdoSe8CpMhFerD5bAuDIWGsBR2hXh4GOpCTdgw/x+lo5Vxc7OBSQAAAABJRU5ErkJggg==);}
.ic--rga-light { width: 40px; background-image: url(data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAKCAQAAAB/RJQvAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQfhDBQPNwnpwBDjAAACgUlEQVQozwXBaUhTAQAH8P97m8ec09rhhTfT1A50DMZcSd6oaDIRY7kgmClUZojTFDMkytQC0Q8KRVn50YoysURM8lgapcnymLrZFNRJtq3ptrft9fsRJQhHSGZi0WD/94VXPQKBwWQ/QuCn3ndzDGjhh/YcmUpHcANC9+43Dxi8IYYHUaFtPWrVxkEAAHIfsnph3coGJ0rvfvycW+Ikpp441ppnU3OP4I+q+msf58a6akZuJZW36lVQwh8WxBdEy89cNYOGB1AUPRzsizNPN9XxUYh52lXdCmDCTm3kQXGCpqvlEhQDaMz8cCMMMiQjgXj5aGpizXIO+SgCmV66OBTUT0vdFi8cJxlwOjzgI9RnfH0bZZVGDL72wAA+DGPT3cABDpEtJ9gPyric5FMU/EDaliQQSm1gnbTDQzPta9JgWZ8qYLlWYQAngelUIwXzEMGDZZxGIHhIzVgf0ezsHaQ1bOMQTJM5gvIFCbeNAEFTNiokv4OQygkXBNgiUxCPbwBESsl5uJeHR99STF4GS6PI3PgpvuQo14KMEv7y2zR5wUWRoEk2j+quSw+DtGUJvqD1lPdNTIOBHU2hMqvCuLCImIvZx0qTGgvFs3ycTXOB/PrFkTiTyQMjkgGb5x/EMZuOpo7Ou0FcLTR9bOQVcFCMCzrq/Q/rit4fkqzqSu3t4Jo76lljcYsdiMOoure9S31FHoV7sWZ69WkNWwCdVeeojUjFs16a7lS2CzQ8/ZaBTkSXbNWUG30dcvh7fx6n6YY4RjySpyxsivV78o+1qsN6aPQJs+wvrQzkZrBincPWoV2jqEKYRuX83X1TP6m73MZhiYK0My8c8pyspF1beOR/V7YPf0WAsdgAAAAASUVORK5CYII=);}
