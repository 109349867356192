.search {
  position: relative;

  &__btn {
    .clear-btn-style;
    width: 30px;
    height: 30px;
    color: #3c3d41;

    position: absolute;
    left: 0;
    top: 0;

    &:hover,
    &:active {
      color: @c-active-secondary;
    }

    span {
      display: none;
    }
  }

  &__field {
    height: 30px;
    padding: 10px 10px 10px 30px;
    border: none;
    border-bottom: 1px solid #e6e6e6;
    border-radius: 0;
    outline: none;

    &:focus {
      border-bottom-color: @c-active-secondary;
    }
  }
}
