.p404 {
  text-align: center;
  color: #d4d4d4;
  font: 700 130px/1 GothamPro;
  margin: 50px 0;

  small {
    display: block;
    font-size: 14px;
    font-weight: 500;
  }

  @media @tablet {
    font-size: 230px;

    small {
      font-size: 16px;
    }
  }

  @media @desktop {
    font-size: 280px;

    small {
      font-size: 18px;
    }
  }
}
