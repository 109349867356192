.social {
  list-style-type: none;
  cursor: default;

  &__item {
    display: inline-block;
    padding: 10px;

    .fa {
      font-size: 15px;
      color: #78787b;
      text-decoration: none;

      &:hover,
      &:active {
        color: @c-active-secondary;
      }
    }
  }

  &--footer {
    margin-bottom: 10px;

    .social__item {
      .fa {
        color: @theme-white;

        &:hover,
        &:active {
          color: @c-active-secondary;
        }
      }

      @media @tablet {
        display: block;
      }
    }
  }
}
