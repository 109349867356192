.input {
  width: 100%;
  height: 45px;
  padding: 0 15px;
  background: @c-bg;
  color: @theme-black;
  border-radius: 7px;
  border: none;
  font-family: GothamPro;
  font-size: 12px;
  font-weight: 400;
  outline-width: 0;
}

.form {

  input {
    .input;

    & + input {
      margin-top: 10px;
    }
  }

}


.select-wr {
  position: relative;

  select {
    padding-right: 40px;
  }

  &::after {
    content: '';
    display: block;
    width: 42px;
    height: 42px;
    position: absolute;
    right: 0;
    top: 0;
    background: url(../../img/dropdown-arr.png) center no-repeat;
  }

  & + label{
    margin-top: 30px;
  }
}

.check-item {
  overflow: hidden;

  & + & {
    margin-bottom: 25px;
  }

  input{
    width: 0;
    height: 0;
    position: absolute;
    left: -9999px;
  }

  label {
    position: relative;
    display: block;
    min-height: 21px;
    line-height: 22px;
    padding-left: 35px;
    margin: 0;

    &::before {
      content: '';
      display: block;
      width: 22px;
      height: 22px;
      border: 2px solid @theme-black;
      padding: 2px;
      position: absolute;
      left: 0;
      top: 0;
      box-sizing: border-box;
      border-radius: 4px;
    }

    .payment-ic-full {
      vertical-align: top;
    }
  }

  input:checked + label::after {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    background: @theme-black;
    position: absolute;
    left: 5px;
    top: 50%;
    margin-top: -4px;
  }
}

.radio-item {
  .check-item;

  label::before {
    border-radius: 50%;
  }

  input:checked + label::after {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    background: @theme-black;
    position: absolute;
    left: 5px;
    top: 50%;
    margin-top: -4px;
    border-radius: 50%;
  }
}
