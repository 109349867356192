table {
  width: 100%;

  tr {
    border-bottom: 1px dashed #f6f6f6;
  }

  th,
  td {
    padding: 8px 15px;
    text-align: left;
  }

  th {
    height: 58px;
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    background-image: linear-gradient(to bottom, #fff 0%, #fff 50%, #f6f6f6 100%);
  }

  td {
    height: 50px;
  }
}
