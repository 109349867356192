.page-footer {
  font-size: 12px;

  a {
    color: @theme-white;
  }

  &__top {
    background-color: #3b3d40;
    padding: 60px 0 40px 0;
    color: @theme-white;
  }

  &__btm {
    padding: 15px;
    background-color: #515357;
    text-align: center;
    color: #b4b4b4;
    font-size: 12px;
  }

  &__link {
    color: #e7e7e7;
    font-family: GothamPro;
    font-size: 12px;
    font-weight: 500;
  }

  @media @mobile {

    &__container {
      display: flex;
      width: 100%;
      justify-content: space-between;
      flex-wrap: wrap;

      padding: 0;
    }

    &__col {
      width: 50%;
      padding: 0 @col-padding;
    }
  }

  @media @tablet {
    &__container {
      flex-wrap: nowrap;
    }

    &__col {
      flex-grow: 1;
      max-width: 225px;

      &--lg {
        max-width: 300px;
      }

      &--xs {
        width: 70px;
        max-width: 70px;
      }
    }
  }

  //TODO: need to fix space-between
  @media @desktop {
    &__col {

      &--lg {
        flex-grow: 2;
      }
    }
  }
}

.icon-bar {
  display: flex;
  align-items: center;
  margin: 17px 0;

  &__item {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
}

.f-nav {
  margin: 30px 0;

  @media @mobile {
    margin-top: 0;
  }

  @media @tablet {
    margin-bottom: 0;
  }

  ul {
    list-style-type: none;
  }

  &__item {
    display: block;
    margin-bottom: 15px;
  }

  &__link {
    font-family: GothamPro;
    font-size: 12px;
    font-weight: 500;
    color: @theme-white;
    text-decoration: none;

    &:hover,
    &:active {
      color: @c-active;
    }
  }
}

.f-btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;

  &__top {
    order: 1;
  }

  @media @tablet {
    flex-direction: column;
    height: 100%;
    margin-top: 0;

    &__top {
      order: 0;
    }
  }
}

.btn-top {
  display: block;
  width: 35px;
  height: 35px;
  border: 1px solid #696969;
  border-radius: 50%;
  text-align: center;
  line-height: 33px;

  .fa {
    color: #696969;
  }

  &:hover,
  &:active {
    border-color: #7a7a7a;

    .fa {
      color: #7a7a7a;
    }
  }

}
