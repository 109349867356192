.page-content {
  width: 100%;
  max-width: @container-width;
  margin: 0 auto;

  &__main {
    padding: @col-padding;
  }

  &__side {
    padding: @col-padding;
  }


  @media @desktop {
    display: flex;
    align-items: stretch;

    &__main {
      .clearfix;
      flex-grow: 1;
      border-right: 1px solid #f4f4f4;
    }

    &__side {
      .clearfix;
      flex: @side-col 0 0;
    }
  }
}

.vpn-bl {
  margin-bottom: 20px;

  @media @desktop {
    margin-bottom: 0;
  }

  &__title {
    margin: 9px 0;
  }
}

.newsletter {
  margin: 20px 0;
  position: relative;

  @media @desktop {
    margin: 0 0 30px 0;
  }

  &__title {
    padding: 22px 17px;
  }

  &__btn {
    position: absolute;
    bottom: 0;
    right: 0;

    height: 45px;
    background: @c-active url(../img/ic-mail.svg) center no-repeat;
    text-indent: -9999px;
    overflow: hidden;
    border-radius: 0 7px 7px 0;
    border: none;

    &:hover {
      background-color: #b1cab4;
    }
  }

  &--light {
    .newsletter__title {
      color: @theme-white;
    }
  }

  &--sm {
    width: 177px;
    margin: 0 0 10px 0;

    .newsletter__title {
      padding: 0;
      margin-bottom: 10px;
      font-family: GothamPro;
      font-size: 12px;
      font-weight: 500;
    }

    .newsletter__btn {
      width: 35px;
      height: 35px;
      padding: 0;
      border-radius: 0 3px 3px 0;
      background-size: 21px auto;
    }

    input {
      height: 35px;
      border-radius: 3px;
    }
  }
}
