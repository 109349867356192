.news {
  .clearfix;
  padding: 23px 17px 10px 17px;
  font-size: 11px;
  border-radius: 7px;
  background: @c-bg;
  background-size: 100px;
  overflow: hidden;
  margin-bottom: 15px;

  &__img {
    float: left;
    display: block;
    width: 100px;
    height: 100px;
    line-height: 100px;
    margin: 0 12px 12px 0;
    overflow: hidden;

    & > img {
      max-width: 100%;
      max-height: 100%;
      vertical-align: middle;
    }
  }

  &__title {
    display: block;
    margin: 5px 0;
    color: #414141;
    font-family: GothamPro;
    font-size: 15px;
    font-weight: 700;
    line-height: 1.1;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__stat {
    display: none;
  }

  &--first {
    padding: 0;

    .news__img {
      float: none;
      width: 100%;
      height: 135px;
      line-height: 135px;
      margin: 0;

      & > img {
        min-width: 100%;
        min-height: 100%;
        max-width: 100%;
        max-height: none;
      }
    }

    .news__inner {
      padding: 15px 17px;
    }

    .news__title {
      margin: 10px 0;
    }

    .news__stat {
      display: block;
    }
  }

  @media (min-width: 480px) {
    .news-on-desktop;
    float: left;
    margin-left: 25px;
    width: calc(~"50% - 25px");

    &:nth-child(2n+1) { clear: both; }
  }

  @media @tablet {
    .news-on-desktop;
    float: left;
    margin-left: 25px;
    width: calc(~"33.3333% - 25px");

    &:nth-child(3n+1) { clear: both; }
    &:nth-child(2n+1) { clear: none; }
  }

  @media @desktop {
    //.news-on-desktop;
    //float: left;
    //margin-left: 25px;
    width: calc(~"25% - 25px");

    &:nth-child(4n+1) { clear: both; }
    &:nth-child(3n+1) { clear: none; }
  }

}

.news-list {
  .clearfix;

  @media (min-width: 480px) {
    margin-left: -25px;
  }
}

.news-bl {

  &__btn-more {
    display: block;
    margin: 25px 0;
    clear: both;
  }

  @media @desktop {
    &__btn-more {
      width: 240px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

//mixin
.news-on-desktop {
  padding: 0;

  &__img {
    float: none;
    width: 100%;
    height: 135px;
    line-height: 135px;
    margin: 0;

    & > img {
      min-width: 100%;
      min-height: 100%;
      max-width: 100%;
      max-height: none;
    }
  }

  &__inner {
    padding: 15px 17px;
  }

  &__title {
    margin: 10px 0;
    font-size: 13px;
  }

  &__stat {
    display: block;
  }
}
