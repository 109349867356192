@font-face { .include-custom-font('GothamPro', @fontfile: 'GothamPro', @weight: 400, @style: normal); }
@font-face { .include-custom-font('GothamPro', @fontfile: 'GothamProMedium', @weight: 500, @style: normal); }
@font-face { .include-custom-font('GothamPro', @fontfile: 'GothamProBold', @weight: 700, @style: normal); }

@font-face { .include-custom-font('Roboto', @fontfile: 'Roboto-Regular-webfont', @weight: 400, @style: normal); }

.font-title {
  font-family: GothamPro, serif;
  font-weight: 500;
  color: @c-mobile-title;

  @media @desktop {
    color: @theme-black;
  }

}

h1.t1 {

}

.t1 {
  .font-title;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 15px 0;

  @media @desktop {
    font-size: 15px;
  }
}

.t2 {
  .font-title;

}

.t3 {
  .font-title;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;

  @media @desktop {
    font-size: 12px;
  }
}
