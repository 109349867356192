.preview {

  &--second {
    .preview__item::before {
      background: url(../../img/ic-arrow--blue.svg);
      background-size: 17px auto;
    }
  }

  &__item {
    .clearfix;
    display: flex;
    align-items: center;
    padding: 24px 17px;
    border-bottom: 1px solid #eeeeee;
    color: @theme-black;
    text-decoration: none;
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 17px;
      height: 6px;
      background: url(../../img/ic-arrow--green.svg);
      background-size: 17px auto;
      opacity: 0;

      position: absolute;
      bottom: 25px;
      right: 25px;

      transition: opacity 0.1s, right 0.3s;
    }

    &:hover {
      &::before {
        opacity: 1;
        right: 14px;
      }

      .preview__title {
        text-decoration: underline;
      }
    }
  }

  &__img {
    width: 60px;
    height: 60px;
    min-width: 60px;

    img {
      display: block;
    }
  }

  &__title {
    padding-left: 15px;
    font-family: GothamPro;
    font-size: 12px;
    font-weight: 700;
  }

}
