.side-bl {
  margin: 0 0 30px 0;
  border-radius: 7px;
  overflow: hidden;

  .side-bl__title {
    .t3;
    padding: 22px 17px;
    background: #fafafa;
  }
  .side-bl__body {
    background: @c-bg;
    box-shadow: inset 0 20px 25px -15px #e8e8e8;
  }
}
