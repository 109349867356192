.btn {
  display: inline-block;
  padding: 0 25px;
  height: 28px;
  line-height: 25px;

  border: 2px solid @c-active;
  border-radius: 4px;

  font-family: GothamPro;
  font-size: 10px;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  background-color: transparent;
  color: @theme-black;
  text-transform: uppercase;
  cursor: pointer;
  .standart-transition(all, 0.3s);

  &:hover {
    background-color: @c-active;
    color: @theme-white;
  }

  &--lg {
    height: 40px;
    line-height: 37px;
  }
}

.clear-btn-style {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  outline-width: 0;
  cursor: pointer;
  .standart-transition(color, 0.3s);
}
